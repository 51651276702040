import React from "react";
import NavigationAppBar from "../../resources/components/navigation/navbar";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Footer from "../../resources/components/landingpage/footer";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function Terms() {
  const { pathname, hash } = useLocation();
  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  }, [pathname, hash]);

  return (
    <>
      <NavigationAppBar black />

      <div className="w-full items-center flex-col py-28 px-5">
        <h2 className="text-center text-[#1b1b1b] font-extrabold text-[32px] uppercase">
          Termos de Serviço
        </h2>
        <p className="text-center uppercase text-[#7F8695] font-semibold text-[14px]">
          Atualizado a 19 de maio de 2023
        </p>

        <div className="mt-16 max-w-4xl mx-auto gap-3 flex flex-col">
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              boxShadow: "none",
              transition: "all 0.5s ease",
              "&.Mui-expanded": {
                backgroundColor: "#26CA90",
              },
              "&::before": {
                content: "none",
              },
            }}
          >
            <AccordionSummary
              sx={{
                color: "#1B1B1B",
                svg: {
                  color: "#26CA90",
                },
                ".Mui-expanded svg": {
                  color: "#FFF",
                },

                ".Mui-expanded": {
                  color: "#FFF",
                },
              }}
              expandIcon={<KeyboardArrowDownIcon />}
              id="ambito"
            >
              <h3 className="text-lg font-semibold">
                Copyright e Propriedade Industrial
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <p className="text-[#FFFFFF] font-normal text-base">
                Todos os dados, marcas e conteúdos gerais deste site são
                propriedade ou uso exclusivo da Vipa e das suas subsidiárias que
                são protegidas pela legislação geral e pela legislação nacional
                e internacional de proteção à propriedade intelectual. A
                reprodução para uso pessoal é permitida, mas modificações não
                autorizadas, imitações, empréstimos, locações, transmissões ou
                vendas de qualquer conteúdo deste site são proibidas.
              </p>
              <p className="text-[#FFFFFF] font-normal text-base">
                O conteúdo das páginas deste site é protegido por Direitos
                Autorais e Direitos Relacionados e Direitos de Propriedade
                Industrial.
              </p>
              <p className="text-[#FFFFFF] font-normal text-base">
                Todos os direitos não concedidos expressamente pela Vipa são
                direitos reservados. Assim, todos os textos, imagens,
                ilustrações, fotografias, publicidade, marcas registadas e
                outros elementos do conteúdo do site são protegidos por lei e
                qualquer cópia, reprodução, disseminação ou transmissão, uso,
                modificação, venda, publicação, distribuição é expressamente
                proibida, ou qualquer outro uso, no todo ou em parte, por
                qualquer meio usado. As excepções a esta proibição são as
                utilizações gratuitas autorizadas por lei, nomeadamente o
                direito de citação, desde que claramente identificada a sua
                origem.
              </p>
              <p className="text-[#FFFFFF] font-normal text-base">
                É concedida permissão para usar documentos (como comunicados de
                imprensa, anúncios, relatórios e contas) deste site, desde que o
                aviso de direitos autorais apareça em todas as cópias. A
                utilização destes documentos deve ser apenas para fins
                informativos, e não para fins comerciais ou pessoais, e não
                podem ser copiados ou colocados em qualquer rede de computador
                ou transmitidos em qualquer outro meio de comunicação. Nenhuma
                modificação nestes documentos foi ainda autorizada. Os
                infratores estarão sujeitos a processo judicial.
              </p>
              <p className="text-[#FFFFFF] font-normal text-base">
                Embora os dados pessoais que nos forneça estejam dentro do campo
                de aplicação da nossa política de proteção de dados, entendemos
                que todas as informações que nos comunicar através da Internet
                (incluindo sugestões, materiais ou ideias) serão da nossa
                propriedade e uso exclusivo se bem restrito. Essas informações
                não serão tratadas por nós como confidenciais.
              </p>
              <p className="text-[#FFFFFF] font-normal text-base">
                Qualquer software disponível para download no site da Vipa
                também está sujeito às leis de proteção de propriedade
                intelectual aplicáveis. Os termos de uso deste software são
                definidos pela operadora que o disponibiliza, portanto,
                recomendamos sua leitura.
              </p>
              <p className="text-[#FFFFFF] font-normal text-base">
                A aplicação utiliza dados de clubes da RapidAPI, sobre os quais
                a mesma não tem qualquer controlo e pelos quais não assume
                qualquer responsabilidade. Todo o conteúdo fornecido pela
                RapidAPI é pertencente à mesma.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              boxShadow: "none",
              transition: "all 0.5s ease",
              "&.Mui-expanded": {
                backgroundColor: "#26CA90",
              },
              "&::before": {
                content: "none",
              },
            }}
          >
            <AccordionSummary
              sx={{
                color: "#1B1B1B",
                svg: {
                  color: "#26CA90",
                },
                ".Mui-expanded svg": {
                  color: "#FFF",
                },

                ".Mui-expanded": {
                  color: "#FFF",
                },
              }}
              expandIcon={<KeyboardArrowDownIcon />}
              id="ambito"
            >
              <h3 className="text-lg font-semibold">Hiperlinks</h3>
            </AccordionSummary>
            <AccordionDetails>
              <p className="text-[#FFFFFF] font-normal text-base">
                Para facilitar a acessibilidade, a Vipa poderá incluir
                hiperligações para outros sites. Ao usar os hiperligações para
                esses sites, deve ler e aceitar as regras desse site antes de
                usá-lo. Não podemos garantir a sua qualidade, nem assumimos
                qualquer responsabilidade pelo conteúdo ou outra funcionalidade
                desses sites.
              </p>
              <p className="text-[#FFFFFF] font-normal text-base">
                A Vipa não se responsabiliza pelo conteúdo ou tema de qualquer
                outro portal, incluindo qualquer site que tenha dado acesso ao
                seu portal ou que tenha sido acedido por meio dele.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              boxShadow: "none",
              transition: "all 0.5s ease",
              "&.Mui-expanded": {
                backgroundColor: "#26CA90",
              },
              "&::before": {
                content: "none",
              },
            }}
          >
            <AccordionSummary
              sx={{
                color: "#1B1B1B",
                svg: {
                  color: "#26CA90",
                },
                ".Mui-expanded svg": {
                  color: "#FFF",
                },

                ".Mui-expanded": {
                  color: "#FFF",
                },
              }}
              expandIcon={<KeyboardArrowDownIcon />}
              id="ambito"
            >
              <h3 className="text-lg font-semibold">Uso e Risco</h3>
            </AccordionSummary>
            <AccordionDetails>
              <p className="text-[#FFFFFF] font-normal text-base">
                Nem a Vipa nem qualquer outra parte envolvida na criação,
                produção, manutenção ou implementação do site será responsável
                por quaisquer danos ou danos decorrentes da responsabilidade
                (incluindo, mas não se limitando a, danos consequenciais, lucros
                cessantes e danos morais causados diretamente ou indiretamente),
                decorrentes do uso correto ou incorreto do site e do seu
                conteúdo pelo utilizador, acesso ao computador e sistema de
                computador do utilizador por terceiros, vírus, etc.
              </p>
              <p className="text-[#FFFFFF] font-normal text-base">
                Embora estejamos empenhados na segurança do seu site e
                implementemos as medidas de protecção técnica, física e lógica
                adequadas para garantir a sua protecção, a Vipa não garante que
                os arquivos disponíveis para download no site estão livres de
                vírus, worms, cavalos de Tróia, scripts ou qualquer outro código
                ou programa que contenha propriedades destrutivas ou
                prejudiciais.
              </p>
              <p className="text-[#FFFFFF] font-normal text-base">
                As informações do site devem ser visualizadas para fins
                informativos. Apesar dos esforços da Vipa para manter o conteúdo
                atualizado e confiável, este pode conter imprecisões, erros
                tipográficos ou conteúdo desatualizado, que pode ser alterado a
                qualquer momento sem aviso prévio da Vipa. Portanto, o seu uso
                nas tomas de decisão não é recomendado.
              </p>
              <p className="text-[#FFFFFF] font-normal text-base">
                Não são permitidos quaisquer tipos de linguagens obscenas.
                Os comportamentos abusivos não serão tolerados e os utilizadores terão o seu acesso à plataforma restrito.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              boxShadow: "none",
              transition: "all 0.5s ease",
              "&.Mui-expanded": {
                backgroundColor: "#26CA90",
              },
              "&::before": {
                content: "none",
              },
            }}
          >
            <AccordionSummary
              sx={{
                color: "#1B1B1B",
                svg: {
                  color: "#26CA90",
                },
                ".Mui-expanded svg": {
                  color: "#FFF",
                },

                ".Mui-expanded": {
                  color: "#FFF",
                },
              }}
              expandIcon={<KeyboardArrowDownIcon />}
              id="ambito"
            >
              <h3 className="text-lg font-semibold">Política de Ação</h3>
            </AccordionSummary>
            <AccordionDetails>
              <p className="text-[#FFFFFF] font-normal text-base">
                É expressamente proibido utilizar o site para fins ilegais ou
                quaisquer outros que possam ser considerados indignos da imagem
                da Vipa ou das suas subsidiárias no mercado.
              </p>
              <p className="text-[#FFFFFF] font-normal text-base">
                Roubo, falsificação, exploração de conteúdo usurpado ou
                falsificado, identificação ilegítima e concorrência desleal são
                puníveis criminalmente.
              </p>
              <p className="text-[#FFFFFF] font-normal text-base">
                Está também proibido de criar ou introduzir neste site qualquer
                tipo de vírus, worms, cavalos de Tróia, scripts ou qualquer
                outro código ou programa que contenha propriedades destrutivas
                ou prejudiciais, ou aconselhar terceiros a fazê-lo.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              boxShadow: "none",
              transition: "all 0.5s ease",
              "&.Mui-expanded": {
                backgroundColor: "#26CA90",
              },
              "&::before": {
                content: "none",
              },
            }}
          >
            <AccordionSummary
              sx={{
                color: "#1B1B1B",
                svg: {
                  color: "#26CA90",
                },
                ".Mui-expanded svg": {
                  color: "#FFF",
                },

                ".Mui-expanded": {
                  color: "#FFF",
                },
              }}
              expandIcon={<KeyboardArrowDownIcon />}
              id="ambito"
            >
              <h3 className="text-lg font-semibold">
                Lei Aplicável e Tribunal Competente
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <p className="text-[#FFFFFF] font-normal text-base">
                Estes Termos de Uso são regidos e interpretados de acordo com a
                lei portuguesa. O Tribunal de Lisboa tem competência para a
                exclusão de qualquer outro para resolver quaisquer litígios
                decorrentes da interpretação e aplicação destes Termos e
                Condições para o uso do site da Vipa.
              </p>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Terms;
