import ExtensionIcon from "@mui/icons-material/Extension";
import InterestsIcon from "@mui/icons-material/Interests";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import React, { useEffect, useRef, useState } from "react";
import {
  FilePlaceHolder,
  ImagePlaceHolder,
} from "../../resources/components/landingpage/imageplaceholder";
import { Scroll } from "../../resources/components/landingpage/scroll";
import { Wrapper } from "../../resources/components/landingpage/wrapper";
import iti from "../../resources/pages/landingpage/images/ITI.svg";
import { ReactComponent as Phones } from "../../resources/pages/landingpage/images/Phones2.svg";
import AboutImage from "../../resources/pages/landingpage/images/about-img.png";
import AboutImage2 from "../../resources/pages/landingpage/images/about-img2.png";
// import applestore_enabled from "../../resources/pages/landingpage/images/applestore_enabled.svg";
import Arditi from "../../resources/pages/landingpage/images/arditi.svg";
import CalltoAction from "../../resources/pages/landingpage/images/calltoaction-home.svg";
import FanIcon from "../../resources/pages/landingpage/images/fan.svg";
import GamificationIcon from "../../resources/pages/landingpage/images/gamification.svg";
// import googlestore from "../../resources/pages/landingpage/images/google-play-badge.png";
import HealthIcon from "../../resources/pages/landingpage/images/health.svg";
import LapTop from "../../resources/pages/landingpage/images/laptop.svg";
import maritimo from "../../resources/pages/landingpage/images/maritimo.png";
import Uma from "../../resources/pages/landingpage/images/uma.svg";
import { GradientBG, GradientBG2, HeroBackground } from "./styles/index-styles";
import { useLocation } from "react-router-dom";
function LandingPage() {
  const revealRef = useRef();
  const [reveal, setReveal] = useState(false);
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (!reveal) {
      const observer = new IntersectionObserver((entries, observer) => {
        const entry = entries[0];
        setReveal(entry.isIntersecting);
      });
      observer.observe(revealRef.current);
    }
    return;
  }, [reveal]);

  useEffect(() => {
    if (hash) {
      let el = document.getElementById(hash.substring(1));
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [pathname, hash]);

  return (
    <>
      <HeroBackground>
        <div className="flex flex-col gap-10 items-center justify-center absolute h-full w-full ">
          <h1 className="font-bold text-white text-4xl sm:text-3xl md:text-5xl lg:text-6xl text-center">
            A Marcar Pela Sua Segurança
          </h1>
          <div className="text-center text-lg">
            <p className="text-white">
              Projeto de Investigação SAFE Football Entrance.{" "}
            </p>
            <p className="text-white">
              O pontapé de saída para uma experiência mais segura.
            </p>
          </div>
          <Scroll />
        </div>
      </HeroBackground>
      <div id="about" className="relative py-10">
        <Wrapper>
          <div className="flex flex-col items-center gap-10 py-20 sm:flex-row">
            <div className="sm:basis-1/2  flex justify-center">
              <ImagePlaceHolder src={AboutImage} />
            </div>
            <div className="basis-full flex flex-col gap-10 sm:basis-1/2 ">
              <h2 className="text-black font-bold text-2xl uppercase sm:text-2xl md:text-3xl lg:text-4xl">
                O pontapé de saída para uma experiência
                <span className="text-[#29B986]"> segura.</span>
              </h2>
              <p className="text-[#3A3A3A] text-lg">
                O projeto SAFE - Football Entrance visa monitorizar o acesso dos
                adeptos aos estádios de futebol perante as mais diversas e
                emergentes doenças infecciosas.
              </p>
              <p className="text-[#3A3A3A] text-lg">
                Em simultâneo, é maximizada a experiência do adepto antes e
                durante a partida, através de funcionalidades de gamificação.
              </p>
            </div>
          </div>
          <div className=" flex items-center justify-around flex-col gap-10 py-10 mb-28 sm:flex-row sm:items-start">
            <div className="flex flex-col items-center justify-center basis-1/3 text-center gap-3">
              <img src={HealthIcon} alt="" />
              <h4 className="uppercase text-[#29B986] font-bold">Saúde</h4>
              <p className="w-2/2 text-[#3A3A3A] text-lg">
                Monitorização das temperaturas dos adeptos à entrada dos
                recintos desportivos, através do check-in rápido pelos
                torniquetes.
              </p>
            </div>
            <div className="flex flex-col items-center justify-center basis-1/3 text-center gap-3">
              <img src={FanIcon} alt="" />
              <h4 className="uppercase text-[#29B986] font-bold">
                adeptos e clubes
              </h4>
              <p className="w-2/3 text-[#3A3A3A] text-lg">
                Todas as informações dos clubes e respetivos jogos são
                concentradas numa única aplicação em tempo real, bem como as
                entradas para os estádios por parte dos adeptos.
              </p>
            </div>
            <div className="flex flex-col items-center justify-center basis-1/3 text-center gap-3">
              <img src={GamificationIcon} alt="" />
              <h4 className="uppercase text-[#29B986] font-bold">
                gamificação
              </h4>
              <p className="w-2/3 text-[#3A3A3A] text-lg">
                Promoção de uma experiência inovadora para eventos desportivos
                através das funcionalidades de gamificação, antes, durante e
                após o jogo.
              </p>
            </div>
          </div>
        </Wrapper>
      </div>
      <GradientBG id="values" className="py-20 relative">
        <Wrapper>
          <h2 className="text-black font-bold text-2xl text-center uppercase sm:text-2xl md:text-3xl lg:text-4xl">
            Os Nosso <span className="text-[#29B986]">Valores</span>
          </h2>
          <div className=" flex items-center justify-around flex-col py-10 gap-5 sm:flex-row sm:items-start">
            <div className="flex flex-col basis-1/3 gap-3">
              <div className="w-full h-2 bg-[#26CA90]" />
              <div className="flex gap-3 items-center">
                <RocketLaunchIcon htmlColor="#26CA90" />
                <h4 className="uppercase  font-bold">INOVAÇÃO</h4>
              </div>
              <p className="text-[#3A3A3A] text-lg">
                A criatividade e a inovação estão no centro da nossa visão para
                o futuro. Acreditamos na criação de soluções inteligentes,
                inovando e desmistificando o sistema de controlo de saúde e os
                jogos tradicionais.
              </p>
            </div>
            <div className="flex flex-col basis-1/3  gap-3">
              <div className="w-full h-2 bg-[#26CA90]" />
              <div className="flex gap-3 items-center">
                <ExtensionIcon htmlColor="#26CA90" />
                <h4 className="uppercase  font-bold">colaboração</h4>
              </div>
              <p className="text-[#3A3A3A] text-lg">
                O SAFE - Football Entrance é um projeto orientado por uma
                colaboração multidisciplinar de especialistas das áreas da
                tecnologia, saúde e design, de modo a serem obtidas soluções
                avançadas, criativas e eficazes.
              </p>
            </div>
            <div className="flex flex-col basis-1/3  gap-3">
              <div className="w-full h-2 bg-[#26CA90]" />
              <div className="flex gap-3 items-center">
                <InterestsIcon htmlColor="#26CA90" />
                <h4 className="uppercase  font-bold">Integridade</h4>
              </div>
              <p className="text-[#3A3A3A] text-lg">
                O SAFE - Football Entrance tem um forte compromisso em
                compreender e satisfazer as necessidades da comunidade na
                procura do bem-estar social.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center gap-10 py-10 sm:flex-row">
            <div className="basis-full flex flex-col gap-10 sm:basis-1/2 ">
              <h2 className="text-black font-bold text-2xl uppercase sm:text-2xl md:text-3xl lg:text-4xl">
                o cartão branco para a
                <span className="text-[#ffffff]"> sua segurança.</span>
              </h2>
              <p className="text-[#3A3A3A] text-lg">
                A contínua presença e constante emergência de novas doenças
                infecciosas na comunidade, têm exposto locais propensos a
                aglomerados de pessoas, como os estádios, a diversas
                vulnerabilidades nos campos da saúde.
              </p>
              <p className="text-[#3A3A3A] text-lg">
                Desta forma, a colaboração multidisciplinar entre especialistas
                das áreas da tecnologia, saúde e design, permite um controlo
                mais eficiente nestes recintos desportivos, diminuindo a
                possibilidade de encerramento em caso de emergência sanitária.
              </p>
            </div>
            <div className="sm:basis-1/2 flex justify-center">
              <ImagePlaceHolder src={AboutImage2} vertical={true} />
            </div>
          </div>
        </Wrapper>
      </GradientBG>
      <div
        className="bg-black relative overflow-hidden"
        style={{ height: "50vh" }}
      >
        <Wrapper>
          <img
            src={CalltoAction}
            alt=""
            style={{ transitionDuration: "2s" }}
            className=" h-full transition-all object-cover absolute inset-x-1/2 -translate-x-1/2 hover:scale-110"
          />
          <div className="flex gap-2 justify-center w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <h2 className=" text-white font-bold text-center text-2xl sm:text-2xl md:text-4xl lg:text-5xl">
              Participa e ganha
            </h2>
            <div class="content">
              <div class="content__container">
                <ul class="content__container__list">
                  <li
                    id="first"
                    class="content__container__list__item font-bold  text-2xl  sm:text-2xl md:text-4xl lg:text-5xl py-2"
                  >
                    pontos
                  </li>
                  <li
                    id="second"
                    class="content__container__list__item font-bold  text-2xl  sm:text-2xl md:text-4xl lg:text-5xl py-2"
                  >
                    emblemas
                  </li>
                  <li
                    id="third"
                    class="content__container__list__item font-bold  text-2xl  sm:text-2xl md:text-4xl lg:text-5xl py-2"
                  >
                    competições
                  </li>
                  <li
                    id="fourth"
                    class="content__container__list__item font-bold  text-2xl  sm:text-2xl md:text-4xl lg:text-5xl  py-2"
                  >
                    prémios!
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
      <GradientBG2 className="py-20">
        <Wrapper>
          <div className="flex flex-col items-center gap-10 py-20 md:flex-row ">
            <div className="sm:basis-1/2 flex justify-center w-full">
              <Phones
                ref={revealRef}
                id="phonesvg"
                className={reveal && "active overflow-visible w-full"}
              />
            </div>
            <div className="basis-full flex flex-col gap-10 sm:basis-1/2 ">
              <h2 className="text-black font-bold text-2xl uppercase sm:text-2xl md:text-3xl lg:text-4xl">
                Aplicação Móvel
                <span className="text-[#fff]"> para Adeptos.</span>
              </h2>
              <p className="text-[#3A3A3A] text-lg">
                Não fiques fora de jogo. Adere ao projeto de investigação SAFE e
                maximiza a tua experiência durante a partida.
              </p>
              <ul className="pl-6 font-semibold text-[#3A3A3A] text-lg">
                <li className="list-disc">
                  Acede a todas as informações do teu clube
                </li>
                <li className="list-disc">
                  Acompanha os jogos do teu clube em tempo real
                </li>
                <li className="list-disc">
                  Participa nas atividades antes e durante o jogo, ganha pontos
                  e recebe prémios
                </li>
                <li className="list-disc">
                  Um projeto de investigação que visa maximizar a tua
                  experiência durante a partida
                </li>
                <li className="list-disc ml-5">O clube: Club Sport Marítimo</li>
                <li className="list-disc ml-5">A época: 2022/2023</li>
                <li className="list-disc ml-5">
                  N.º de utilizadores: 639 adeptos
                </li>
                <li className="list-disc ml-5">Média de temperaturas: 36,59ºC</li>
              </ul>
              {/*          <div className="flex flex-col">
                <p className="font-bold text-[#3A3A3A] ml-2 text-lg mb-2">
                  Disponível nas lojas
                </p>
                <div className="flex flex-col items-center sm:flex-row gap-5">
                  {" "}
                  <a
                    href="https://apps.apple.com/us/app/safe-football-entrance/id6448782936"
                    target="_blank"
                    rel="noopenner noreferrer"
                  >
                    <img
                      src={applestore_enabled}
                      width={"180px"}
                      alt=""
                      className="max-w-[200px]"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noopenner noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.safe_mobile"
                  >
                    <img
                      src={googlestore}
                      alt=""
                      className="max-w-[200px]"
                      width={"200px"}
                    />
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </Wrapper>
        <Wrapper>
          <div className="flex flex-col items-center gap-10 py-20 sm:flex-row-reverse">
            <div className="sm:basis-1/2  flex justify-center">
              <img src={LapTop} alt="" />
            </div>
            <div className="basis-full flex flex-col gap-10 sm:basis-1/2 ">
              <h2 className="text-black font-bold text-2xl uppercase sm:text-2xl md:text-3xl lg:text-4xl">
                Plataforma Desktop
                <span className="text-[#ffffff]"> para Clubes.</span>
              </h2>
              <p className="text-[#3A3A3A] text-lg">
                Apitamos para a proximidade entre os clubes e os adeptos.
              </p>
              <ul className="pl-6  font-semibold text-[#3A3A3A] text-lg">
                <li className="list-disc">
                  Registar calendários de jogos e jogadores
                </li>
                <li className="list-disc">
                  Aceder à lista de sócios e estatísticas
                </li>
                <li className="list-disc">Adicionar prémios na loja online</li>
              </ul>
            </div>
          </div>
        </Wrapper>
      </GradientBG2>
      <div id="partners" className="relative">
        <Wrapper>
          <div className="py-12 min-h-[50vh] flex flex-col items-center relative z-[1]">
            <h2 className="text-black font-bold text-3xl text-center uppercase mb-5 sm:text-3xl md:text-4xl lg:text-5xl">
              Os Nossos <span className="text-[#29B986]"> Parceiros</span>
            </h2>
            <div className="flex flex-col py-10 sm:flex-row gap-5 text-lg">
              <div className="flex flex-col items-center basis-1/4 text-center gap-10 justify-between">
                <img src={Uma} alt="" className="flex-1" />
                <p className="text-[#3A3A3A] text-base text-center font-semibold">
                  Universidade da Madeira
                </p>
              </div>
              <div className="flex flex-col items-center basis-1/4 text-center gap-10 justify-between">
                <img src={Arditi} alt="" className="flex-1" />
                <p className="text-[#3A3A3A] text-base text-center font-semibold">
                  Agência Regional para o Desenvolvimento da Investigação,
                  Tecnologia e Inovação
                </p>
              </div>
              <div className="flex flex-col items-center basis-1/4 text-center gap-10 justify-between">
                <img src={iti} alt="" className="flex-1" />
                <p className="text-[#3A3A3A] text-base text-center font-semibold">
                  Instituto de Tecnologias Interativas
                </p>
              </div>
              <div className="flex flex-col items-center basis-1/4 text-center gap-10 justify-between">
                <img src={maritimo} alt="" className="flex-1" />
                <p className="text-[#3A3A3A] text-base text-center font-semibold">
                  Club Sport Marítimo
                </p>
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
      <div id="tecnica" className="bg-[#F1F1F1] py-10 relative ">
        <Wrapper>
          <h2 className="text-black font-bold text-2xl text-center uppercase mb-5 sm:text-2xl md:text-3xl lg:text-4xl">
            Ficha Técnica
          </h2>
          <FilePlaceHolder />
        </Wrapper>
      </div>
    </>
  );
}

export default LandingPage;
