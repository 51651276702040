import { ReactComponent as ScrollIcon } from "../../pages/landingpage/icons/scroll.svg";
import { ScrollIconWrap } from "../../../routes/landingpage/styles/index-styles";

export const Scroll = () => {
  return (
    <ScrollIconWrap>
      <ScrollIcon />
    </ScrollIconWrap>
  );
};
