import styled from "styled-components";
import ImgBackground from "../../../resources/pages/landingpage/images/landing-hero.png";

export const HeroBackground = styled("div")`
  width: 100%;
  min-height: 100vh;
  background-image: url(${ImgBackground});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`;

export const ScrollIconWrap = styled("div")`
  position: absolute;
  bottom: 10%;
  & svg {
    position: relative;

    & #scroll #ball {
      animation: move-up 3s ease-in-out infinite;
      position: absolute;

      @keyframes move-up {
        0% {
          opacity: 0;
        }
        10% {
          opacity: 1;
          translate: 0 -15px;
        }
        90% {
          translate: 0 3px;
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }
  }
`;
export const GradientBG = styled("div")`
  width: 100%;
  background: linear-gradient(180deg, #f1f1f1 0%, #8fc2ae 100%);
`;
export const GradientBG2 = styled("div")`
  width: 100%;
  background: linear-gradient(180deg, #b6d5c8 -20%, #8fc2ae 100%);
`;
