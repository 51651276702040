import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./resources/components/landingpage/footer";
import NavigationAppBar from "./resources/components/navigation/navbar";
import LandingPage from "./routes/landingpage";
import PrivacyPolicy from "./routes/privacypolicy";
import Terms from "./routes/terms";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms" element={<Terms />} />
        <Route
          path="*"
          element={
            <>
              <NavigationAppBar />
              <LandingPage />
              <Footer />
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
