import React from "react";
import NavigationAppBar from "../../resources/components/navigation/navbar";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Footer from "../../resources/components/landingpage/footer";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function PrivacyPolicy() {
  const { pathname, hash } = useLocation();
  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  }, [pathname, hash]);

  return (
    <>
      <NavigationAppBar black />

      <div className="w-full items-center flex-col py-28 px-5">
        <h2 className="text-center text-[#1b1b1b] font-extrabold text-[32px] uppercase">
          Política de Privacidade
        </h2>
        <p className="text-center uppercase text-[#7F8695] font-semibold text-[14px]">
          Atualizado a 19 de maio de 2023
        </p>

        <div className="mt-16 max-w-4xl mx-auto gap-3 flex flex-col">
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              boxShadow: "none",
              transition: "all 0.5s ease",
              "&.Mui-expanded": {
                backgroundColor: "#26CA90",
              },
              "&::before": {
                content: "none",
              },
            }}
          >
            <AccordionSummary
              sx={{
                color: "#1B1B1B",
                svg: {
                  color: "#26CA90",
                },
                ".Mui-expanded svg": {
                  color: "#FFF",
                },

                ".Mui-expanded": {
                  color: "#FFF",
                },
              }}
              expandIcon={<KeyboardArrowDownIcon />}
              id="ambito"
            >
              <h3 className="text-lg font-semibold">Âmbito</h3>
            </AccordionSummary>
            <AccordionDetails>
              <p className="text-[#FFFFFF] font-normal text-base">
                A Política de Privacidade aplica-se aos dados recolhidos e
                tratados pelo SAFE: Safe Football Entrance, designadamente
                através de (i) sítio da internet (https://www.futsafe.com/),
                (ii) na aplicação móvel SAFE e, (iii) nas comunicações efetuadas
                via notificações, e-mails ou outros canais de comunicação.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              boxShadow: "none",
              transition: "all 0.5s ease",
              "&.Mui-expanded": {
                backgroundColor: "#26CA90",
              },
              "&::before": {
                content: "none",
              },
            }}
          >
            <AccordionSummary
              sx={{
                color: "#1B1B1B",
                svg: {
                  color: "#26CA90",
                },
                ".Mui-expanded svg": {
                  color: "#FFF",
                },

                ".Mui-expanded": {
                  color: "#FFF",
                },
              }}
              expandIcon={<KeyboardArrowDownIcon />}
              id="ambito"
            >
              <h3 className="text-lg font-semibold">
                Declaração de Compromisso com a Privacidade e Proteção de Dados
                Pessoais
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <p className="text-[#FFFFFF] font-normal text-base">
                O SAFE, enquanto plataforma que lida diariamente com dados
                pessoais, considera-se comprometida com as matérias reguladas e
                as obrigações impostas pela legislação europeia sobre proteção
                de dados constituída, nomeadamente pelo Regulamento (EU)
                2016/679 do Parlamento e do Conselho (relativo à proteção das
                pessoas singulares no que diz respeito ao tratamento de dados
                pessoais e à livre circulação desses dados) doravante designado
                RGPD, assim como pela Lei 58/2019, de 8 de agosto que assegura a
                execução do referido regulamento no contexto português.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              boxShadow: "none",
              transition: "all 0.5s ease",
              "&.Mui-expanded": {
                backgroundColor: "#26CA90",
              },
              "&::before": {
                content: "none",
              },
            }}
          >
            <AccordionSummary
              sx={{
                color: "#1B1B1B",
                svg: {
                  color: "#26CA90",
                },
                ".Mui-expanded svg": {
                  color: "#FFF",
                },

                ".Mui-expanded": {
                  color: "#FFF",
                },
              }}
              expandIcon={<KeyboardArrowDownIcon />}
              id="ambito"
            >
              <h3 className="text-lg font-semibold">
                O Responsável pelo Tratamento de Dados
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <p className="text-[#FFFFFF] font-normal text-base">
                A entidade responsável pela recolha e tratamento dos dados
                pessoais é a VGPC Lda., com o número de identificação de pessoa
                coletiva 515 381 632, com instalações na Rua Dr. Fernão de
                Ornelas nº56, 4ºAC, 9050-021 Funchal, telefone: +351 291 600
                300, e-mail: dpo@vipacorporation.com, que no seu contexto,
                decide quais os dados recolhidos, os meios de tratamento, o
                período de conservação e as finalidades para que são utilizados.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              boxShadow: "none",
              transition: "all 0.5s ease",
              "&.Mui-expanded": {
                backgroundColor: "#26CA90",
              },
              "&::before": {
                content: "none",
              },
            }}
          >
            <AccordionSummary
              sx={{
                color: "#1B1B1B",
                svg: {
                  color: "#26CA90",
                },
                ".Mui-expanded svg": {
                  color: "#FFF",
                },

                ".Mui-expanded": {
                  color: "#FFF",
                },
              }}
              expandIcon={<KeyboardArrowDownIcon />}
              id="ambito"
            >
              <h3 className="text-lg font-semibold">
                Utilização e Finalidades dos Dados Pessoais Recolhidos
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <p className="text-[#FFFFFF] font-normal text-base">
                Os dados pessoais são recolhidos e necessários para assegurar a
                identidade do adepto, sendo o fornecimento dos dados solicitados
                no acto de registo na plataforma SAFE obrigatório para que o
                adepto/ sócio possa usufruir dos serviços disponíveis. No acto
                de registo, a informação disponibilizada tem como objectivo a
                prestação de um serviço mais adequado às características e
                necessidades e ao envio de informações relacionadas com a
                utilização da aplicação móvel SAFE.
              </p>
              <p className="text-[#FFFFFF] font-normal text-base">
                Além das finalidades acima referidas, o SAFE utilizará os dados
                pessoais para efeitos de investigação científica no âmbito do
                projeto SAFE: Safe Football Entrance em parceria com o Club
                Sport Marítimo. Por dados pessoais compreende-se os dados
                associados aos sócios, nomeadamente, nome, género, data de
                nascimento, dados de contacto e dados de sócio (n.º de sócio,
                data de admissão, quotas, n.º de cartão, bancada, porta, setor,
                fila e banco).
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              boxShadow: "none",
              transition: "all 0.5s ease",
              "&.Mui-expanded": {
                backgroundColor: "#26CA90",
              },
              "&::before": {
                content: "none",
              },
            }}
          >
            <AccordionSummary
              sx={{
                color: "#1B1B1B",
                svg: {
                  color: "#26CA90",
                },
                ".Mui-expanded svg": {
                  color: "#FFF",
                },

                ".Mui-expanded": {
                  color: "#FFF",
                },
              }}
              expandIcon={<KeyboardArrowDownIcon />}
              id="ambito"
            >
              <h3 className="text-lg font-semibold">
                Partilha e Divulgação dos Dados
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <p className="text-[#FFFFFF] font-normal text-base">
                O SAFE só partilha dados pessoais com terceiros se tiver
                fundamento legal para o efeito, como seja o consentimento do
                titular dos dados, o cumprimento de obrigações legais que lhe
                estão cometidas, ou o exercício de funções de interesse público.
              </p>
              <p className="text-[#FFFFFF] font-normal text-base">
                Fora dessas situações, não transmitimos, vendemos ou trocamos os
                seus dados pessoais com terceiros, sendo que os mesmos serão
                apenas armazenados e tratados dentro da infraestrutura
                tecnológica do Instituto de Informática da Segurança Social IP
                cujas medidas são da responsabilidade do Instituto de
                Informática, IP no âmbito das atribuições deste organismo que
                decorrem da Orgânica do Ministério do Trabalho, da Solidariedade
                e Segurança Social.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              boxShadow: "none",
              transition: "all 0.5s ease",
              "&.Mui-expanded": {
                backgroundColor: "#26CA90",
              },
              "&::before": {
                content: "none",
              },
            }}
          >
            <AccordionSummary
              sx={{
                color: "#1B1B1B",
                svg: {
                  color: "#26CA90",
                },
                ".Mui-expanded svg": {
                  color: "#FFF",
                },

                ".Mui-expanded": {
                  color: "#FFF",
                },
              }}
              expandIcon={<KeyboardArrowDownIcon />}
              id="ambito"
            >
              <h3 className="text-lg font-semibold">Conservação dos Dados</h3>
            </AccordionSummary>
            <AccordionDetails>
              <p className="text-[#FFFFFF] font-normal text-base">
                O SAFE apenas conserva os dados pessoais recolhidos e tratados
                pelo período de tempo previsto na legislação aplicável ou, não
                havendo previsão legal, pelo hiato temporal estritamente
                necessário para eventos de investigação científica no âmbito do
                projeto de investigação SAFE: Safe Football Entrance, que
                presidiram à sua recolha.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              boxShadow: "none",
              transition: "all 0.5s ease",
              "&.Mui-expanded": {
                backgroundColor: "#26CA90",
              },
              "&::before": {
                content: "none",
              },
            }}
          >
            <AccordionSummary
              sx={{
                color: "#1B1B1B",
                svg: {
                  color: "#26CA90",
                },
                ".Mui-expanded svg": {
                  color: "#FFF",
                },

                ".Mui-expanded": {
                  color: "#FFF",
                },
              }}
              expandIcon={<KeyboardArrowDownIcon />}
              id="ambito"
            >
              <h3 className="text-lg font-semibold">Aviso Legal</h3>
            </AccordionSummary>
            <AccordionDetails>
              <p className="text-[#FFFFFF] font-normal text-base">
                O conteúdo desta aplicação móvel (incluindo, mas não se
                limitando a texto, imagens, componentes de áudio e vídeo,
                doravante conteúdos) é protegido pela Lei de Propriedade
                Intelectual. A distribuição, modificação, transmissão, cópia ou
                qualquer uso do conteúdo da plataforma para fins públicos ou
                comerciais sem a permissão da empresa é uma violação da lei
                aplicável. É-lhe atribuída também uma licença gratuita, pessoal
                e não exclusiva para usar, copiar, imprimir e fazer download de
                qualquer material ou documento disponível no site para o seu
                computador pessoal.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              boxShadow: "none",
              transition: "all 0.5s ease",
              "&.Mui-expanded": {
                backgroundColor: "#26CA90",
              },
              "&::before": {
                content: "none",
              },
            }}
          >
            <AccordionSummary
              sx={{
                color: "#1B1B1B",
                svg: {
                  color: "#26CA90",
                },
                ".Mui-expanded svg": {
                  color: "#FFF",
                },

                ".Mui-expanded": {
                  color: "#FFF",
                },
              }}
              expandIcon={<KeyboardArrowDownIcon />}
              id="ambito"
            >
              <h3 className="text-lg font-semibold">
                Resolução Alternativa de Disputa
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <p className="text-[#FFFFFF] font-normal text-base">
                A resolução alternativa de litígios é a possibilidade de todos
                os consumidores recorrerem a entidades oficiais para os auxiliar
                na resolução ou direcionamento de qualquer conflito antes de
                iniciarem o litígio nos tribunais. Regra geral, o procedimento é
                o seguinte: O cliente pede a um terceiro imparcial que atue como
                intermediário entre ele e o comerciante submetido à reclamação.
                O intermediário pode sugerir uma solução para a sua reclamação,
                impor uma solução a ambas as partes ou reunir as partes para
                encontrar uma solução. Pode conhecer o conceito de resolução
                alternativa de disputas por outro nome: 'mediação',
                'conciliação', 'arbitragem' ou 'comissão responsável por
                disputas de consumo'. A resolução alternativa de disputas é
                geralmente menos dispendiosa, menos formal e mais rápida do que
                a via judicial.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              boxShadow: "none",
              transition: "all 0.5s ease",
              "&.Mui-expanded": {
                backgroundColor: "#26CA90",
              },
              "&::before": {
                content: "none",
              },
            }}
          >
            <AccordionSummary
              sx={{
                color: "#1B1B1B",
                svg: {
                  color: "#26CA90",
                },
                ".Mui-expanded svg": {
                  color: "#FFF",
                },

                ".Mui-expanded": {
                  color: "#FFF",
                },
              }}
              expandIcon={<KeyboardArrowDownIcon />}
              id="ambito"
            >
              <h3 className="text-lg font-semibold">
                Responsável pelo conteúdo e Atualização da Aplicação
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <p className="text-[#FFFFFF] font-normal text-base">
                A empresa isenta-se de qualquer responsabilidade decorrente do
                uso indevido do conteúdo e reserva-se o direito de atualizar,
                modificar ou excluir as informações contidas no site e de
                limitar ou não permitir o acesso a essas informações em tempo
                oportuno ou definitivo. As informações contidas nestas páginas
                da web são atuais na data da sua última atualização e devem ser
                consideradas como informações orientadas ao utilizador sobre
                produtos e serviços e outras informações adicionais.
              </p>
              <p className="text-[#FFFFFF] font-normal text-base">
                A empresa não se responsabiliza por quaisquer discrepâncias que
                possam surgir entre a versão dos seus documentos impressos e a
                versão eletrónica dos mesmos publicada no site. Os conteúdos,
                atividades comerciais, produtos e serviços incluídos no site da
                empresa e quaisquer relacionados ou vinculados. Simultaneamente,
                são dirigidos a consumidores residentes em Portugal, pelo que em
                qualquer caso é aplicável a Legislação Portuguesa e os Tribunais
                Portugueses competentes.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              backgroundColor: "#F9F9F9",
              boxShadow: "none",
              transition: "all 0.5s ease",
              "&.Mui-expanded": {
                backgroundColor: "#26CA90",
              },
              "&::before": {
                content: "none",
              },
            }}
          >
            <AccordionSummary
              sx={{
                color: "#1B1B1B",
                svg: {
                  color: "#26CA90",
                },
                ".Mui-expanded svg": {
                  color: "#FFF",
                },

                ".Mui-expanded": {
                  color: "#FFF",
                },
              }}
              expandIcon={<KeyboardArrowDownIcon />}
              id="ambito"
            >
              <h3 className="text-lg font-semibold">Política de Privacidade</h3>
            </AccordionSummary>
            <AccordionDetails>
              <p className="text-[#FFFFFF] font-normal text-base">
                Os dados fornecidos são confidenciais e serão incorporados num
                arquivo automatizado da empresa pelo qual a empresa é
                responsável. Os candidatos autorizam a empresa a transferir os
                dados para as empresas do seu grupo, a fim de cumprir com
                diligência o seu pedido. O requerente tem o direito de aceder,
                retificar e cancelar estes dados, quer por via eletrónica, quer
                por pedido de correio eletrónico através do endereço a que se
                referem os dados eletrónicos.
              </p>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
