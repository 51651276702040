import React, { useEffect, useState } from "react";
import { NavigationBar, NavigationLinks } from "./styles/styles";
import { ReactComponent as Logo } from "../../logos/safe-logo-min.svg";
import { ReactComponent as LogoSecondary } from "../../logos/safe-logo-min-b.svg";
import LanguageIcon from "@mui/icons-material/Language";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { Alert, Box, Modal, Snackbar } from "@mui/material";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import LockIcon from "@mui/icons-material/LockOutlined";
import MailIcon from "@mui/icons-material/EmailOutlined";

const Button = styled.button.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 3.125rem;
  max-height: 80px;
  min-height: 46px;
  min-width: 64px;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.textcolor};
  box-sizing: border-box;
  padding: 0 1rem;
  outline: 0;
  border: 0;
  cursor: pointer;
  &.small {
    width: 130px;
  }
  &.medium {
    width: 200px;
  }
  &.large {
    width: 280px;
  }
  &.adaptive {
    width: auto;
  }
  &.fullwidth {
    width: 100%;
  }
  &.btn-primary {
    background-color: #4566af;
  }
  &.btn-secondary {
    background-color: #ffffff;
  }
  &.btn-alternative {
    background-color: #4566af;
  }
  &.btn-login {
    background-color: #57ceec;
  }
  &.centered {
    margin: 0 auto;
    align-self: center;
  }
  &.right {
    margin-left: auto;
    align-self: flex-end;
  }
  &.left {
    margin-right: auto;
    align-self: flex-start;
  }
`;
const STYLES = ["btn-primary", "btn-secondary", "btn-alternative", "btn-login"];
const SIZES = ["small", "medium", "large", "adaptive", "fullwidth"];
const ALIGNS = ["", "centered", "right", "left"];

function SAButton({
  style,
  value,
  onclick,
  size,
  endIcon,
  type,
  textcolor,
  align,
  bgColor,
  disable,
}) {
  const CheckButtonStyle = style
    ? STYLES.includes(style[0])
      ? style
      : STYLES[0]
    : STYLES[0];
  const CheckButtonSize = SIZES.includes(size) ? size : SIZES[0];
  const CheckButtonAlign = ALIGNS.includes(align) ? align : ALIGNS[0];
  const theme = {
    textcolor: textcolor,
  };
  return (
    <Button
      disabled={disable}
      style={bgColor && { backgroundColor: bgColor }}
      theme={theme}
      onClick={onclick}
      className={`${CheckButtonStyle} ${CheckButtonSize} ${CheckButtonAlign}`}
      type={type}
    >
      <span>{value}</span>
      {endIcon ?? null}
    </Button>
  );
}

const LoginTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  text-align: left;
  & p {
    margin: 0;
  }
`;

const InputBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
  margin: 2rem auto 1rem auto;
  border-bottom: 0.5px solid #4566af;
  box-sizing: border-box;
  padding: 0.625rem 0;

  & input {
    outline: none;
    width: 100%;
    border: 0;
    &::placeholder {
      color: #092f8380;
    }
  }
`;

// const InputHelpers = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: 2.5rem;
//   & label {
//     color: #a1a1a1;
//   }
//   & a {
//     text-decoration: none;
//     color: #5f80f7;
//   }
// `;

const LoginStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  textAlign: "center",
  border: "none",
  transform: "translate(-50%, -50%)",
  bgcolor: "#FFFFFF",
  boxShadow: "0px 4px 10px rgba(37, 49, 151, 0.05)",
  borderRadius: "30px",
  boxSizing: "border-box",
  p: "3rem",
};
const LoginMobile = {
  position: "absolute",
  top: "100px",
  left: "0",
  right: "auto",
  bottom: "auto",
  width: "100%",
  height: "60%",
  textAlign: "center",
  border: "none",
  background: "#FFFFFF",
  boxShadow: "0px 4px 10px rgba(37, 49, 151, 0.05)",
  borderRadius: "30px",
  boxSizing: "border-box",
  padding: "1.25rem",
};

function NavigationAppBar({ black }) {
  const [scrolled, setScrolled] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1250px)" });
  console.log(isTabletOrMobile);

  function openLoginModal(e) {
    console.log("opening");
    e.preventDefault();
    setIsOpen(true);
    // closeRegisterModal(false);
    toggleMenu();
  }
  function closeLoginModal() {
    setIsOpen(false);
  }

  function toggleMenu() {
    if (isTabletOrMobile) setOpen(!open);
  }

  useEffect(() => {
    if (!black) {
      window.addEventListener("scroll", () => {
        if (window.scrollY > 100) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      });
    }
    if (open) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "auto";
    }
  }, [scrolled, open, black]);

  return (
    <>
      <NavigationBar black={black} className={scrolled && "scrolled"}>
        <div className="flex items-center justify-end relative md:justify-between max-h-16 w-full lg:w-4/5 sm:5/6 mx-auto">
          {scrolled ? (
            <Link
              className="max-h-16 md:max-h-12 absolute inset-x-1/2 -translate-x-1/2 md:relative md:inset-x-auto md:translate-x-0"
              to="/"
            >
              <LogoSecondary className="max-h-16 md:max-h-12" />
            </Link>
          ) : (
            <Link
              className="max-h-16 md:max-h-12 absolute inset-x-1/2 -translate-x-1/2 md:relative md:inset-x-auto md:translate-x-0"
              to="/"
            >
              <Logo className="max-h-16 md:max-h-12" />
            </Link>
          )}

          <NavigationLinks
            className={`${scrolled && "scrolled"} + ${open && "active"}`}
          >
            <Link to="/#about" onClick={open && (() => setOpen(false))}>
              Sobre nós
            </Link>
            <Link to="/#partners" onClick={open && (() => setOpen(false))}>
              Parceiros
            </Link>
            <Link to="/#footer" onClick={open && (() => setOpen(false))}>
              Contactos
            </Link>
            <div className="lang items-center gap-3 flex mt-5 md:hidden">
              <LanguageIcon htmlColor={"#fff"} />
              <p className={"text-white font-bold"}>PT</p>
            </div>
          </NavigationLinks>
          <div className="lang items-center gap-3 hidden md:flex">
            <div
              className="rounded-full font-bold hover:invert"
              style={{
                backgroundColor: scrolled ? "#1b1b1b" : "#FFF",
                color: !scrolled ? "#1b1b1b" : "#FFF",
                borderColor: !scrolled ? "#1b1b1b" : "#FFF",
                borderWidth: "1px",
              }}
            >
              <Button onClick={openLoginModal} href="/#">
                Login
              </Button>
            </div>
            <LanguageIcon htmlColor={scrolled ? "#000000A6" : "#ffffffA6"} />
            <p
              className={`${scrolled ? "text-black" : "text-white"} font-bold`}
            >
              PT
            </p>
          </div>
          <div className="block pr-4 md:hidden">
            {open ? (
              <CloseIcon
                onClick={() => setOpen(!open)}
                htmlColor={scrolled ? "#3A3A3A" : "#fff"}
                className="relative z-10 justify-self-end cursor-pointer"
              />
            ) : (
              <MenuIcon
                onClick={() => setOpen(!open)}
                htmlColor={scrolled ? "#3A3A3A" : "#fff"}
                className="relative z-10 justify-self-end cursor-pointer"
              />
            )}
          </div>
        </div>
      </NavigationBar>
      <Modal
        open={modalIsOpen}
        onClose={closeLoginModal}
        contentLabel="Pop up de Login"
      >
        <Box sx={isTabletOrMobile ? LoginMobile : LoginStyles}>
          <LoginTitle>
            <h2 className="text-3xl text-center font-medium">Entrar</h2>
            <p className="text-lg text-center">
              Aceda à sua conta para continuar
            </p>
          </LoginTitle>
          {/* 
        <form
          // onSubmit={(e) => {
          //   SendLoginRequest(e);
          // }}
        > */}
          <InputBox>
            <MailIcon />
            <input
              type="text"
              name="email"
              placeholder="Email"
              // onChange={(event) => setLoginEmail(event.target.value)}
            />
          </InputBox>

          <InputBox>
            <LockIcon />
            <input
              type="password"
              name="password"
              placeholder="Password"
              // onChange={(event) => setLoginPassword(event.target.value)}
            />
          </InputBox>
          {/*<InputHelpers>
            <label>
              <input type="checkbox" name="remember" />
              Lembrar
            </label>
            <span>
              <a
                href="/#"
                onClick={() => {
                  // setForgotPasswordModal(true);
                  closeLoginModal();
                }}
              >
                Esqueci-me da palavra-passe?
              </a>
            </span>
              </InputHelpers>*/}
          <div style={{ width: "100%" }}>
            <SAButton
              disable={true}
              type={"submit"}
              value={"Login"}
              style={["btn-login"]}
              size={"fullwidth"}
              textcolor={"#fff"}
              bgColor={"#1b1b1b"}
              centered={true}
            />
          </div>
          <p className="text-center mt-5">
            Indisponível a partir de 30 de junho de 2023
          </p>
          {/* </form> */}
          {snackOpen && (
            <Snackbar
              open={snackOpen}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={() => setSnackOpen(false)}
            >
              <Alert severity="error" sx={{ width: "100%" }}>
                Email ou Password incorrectos!
              </Alert>
            </Snackbar>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default NavigationAppBar;
