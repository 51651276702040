import styled from "styled-components";
import FileFooter from "../../pages/landingpage/images/file-footer.svg";
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';

const ImageWrap = styled("div")`
  width: 100%;
  position: relative;
  max-height: 700px;
  max-width: 700px;
  z-index: 1;
  &::before {
    position: absolute;
    content: "";
    width: 170px;
    height: 90px;
    background-color: #26ca90;
    top: -5px;
    left: -5px;
    z-index: -1;
  }
  &::after {
    position: absolute;
    content: "";
    width: 170px;
    height: 90px;
    background-color: #26ca90;
    bottom: -5px;
    right: -5px;
    z-index: -1;
  }
`;
const ImageWrapVertical = styled("div")`
  width: 100%;
  position: relative;
  max-height: 700px;
  max-width: 500px;
  z-index: 1;

  & .img-wrap {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 1;

    &::before {
      position: absolute;
      content: "";
      width: 170px;
      height: 90px;
      background-color: #ffffff;
      top: -10px;
      right: -10px;
      z-index: -1;
    }
    &::after {
      position: absolute;
      content: "";
      width: 170px;
      height: 90px;
      background-color: #ffffff;
      bottom: -10px;
      left: -10px;
      z-index: -1;
    }

    & img {
      width: 100%;
      max-height: 700px;
    }
  }
`;
const FileWrap = styled("div")`
  width: 100%;
  position: relative;
  z-index: 1;
  &::before {
    position: absolute;
    content: "";
    width: 90px;
    height: 170px;
    background-color: #26ca90;
    top: -10px;
    left: -10px;
    z-index: -1;
  }
  &::after {
    position: absolute;
    content: "";
    width: 90px;
    height: 170px;
    background-color: #26ca90;
    bottom: -10px;
    right: -10px;
    z-index: -1;
  }
`;
export const ImagePlaceHolder = ({ src, vertical }) => {
  return (
    <>
      {vertical ? (
        <ImageWrapVertical>
          <div className="img-wrap">
            <img src={src} alt="" className="object-cover" />
          </div>
        </ImageWrapVertical>
      ) : (
        <ImageWrap>
          <img src={src} alt="" className="object-contain" />
        </ImageWrap>
      )}
    </>
  );
};
export const FilePlaceHolder = () => {
  return (
    <>
      <FileWrap>
        <div className="bg-white p-5 w-full box-border text-lg">
          <ul className="pl-8 font-semibold text-[#3A3A3A]">
            <li className="list-disc">
              Designação da operação:{" "}
              <span className="font-normal">
                SAFE - Safe Football Entrance [SAFE] n.º
                M1420-01-0247-FEDER-000067
              </span>
            </li>
            <li className="list-disc">
              Identificação do Programa Operacional:
              <span className="font-normal"> Madeira 14-20;</span>
            </li>
            <li className="list-disc">
              Custo Total do Investimento:
              <span className="font-normal">
                909.356,69€, parcialmente comparticipado pelo Fundo Europeu de
                Desenvolvimento Regional (FEDER) e pelo Orçamento Regional (OR).
              </span>
            </li>
            <li className="list-disc">
              Eixo Prioritário 1:{" "}
              <span className="font-normal">
                Reforçar a Investigação, o Desenvolvimento Tecnológico e a
                Inovação;
              </span>
            </li>
            <li className="list-disc">
              Prioridade de Investimento: 1.b:
              <span className="font-normal">
                {" "}
                Promoção do investimento das empresas em inovação e
                investigação, o desenvolvimento de ligações e sinergias entre
                empresas, centros de Investigação e Desenvolvimento (I&D).
              </span>
            </li>
            <li className="list-disc">
              Tipologia de Intervenção:{" "}
              <span className="font-normal">
                Investimento produtivo relacionado com a cooperação entre
                grandes empresas e PME para o desenvolvimento de produtos e
                serviços de «TIC» e do comércio eletrónico e para fomentar a
                procura de competências TIC.
              </span>
            </li>
            <li className="list-disc">
              Ação:{" "}
              <span className="font-normal">
                Investimento produtivo relacionado com a cooperação entre
                grandes empresas e PME para o desenvolvimento de produtos e
                serviços de «TIC» e do comércio eletrónico e para fomentar a
                procura de competências TIC.
              </span>
            </li>
            <li className="list-disc">
              Projeto da VGPC, Lda, em Co-Promoção com a ARDITI e a UMa;
            </li>
            <li className="list-disc">
              Calendarização da Operação:{" "}
              <span className="font-normal">
                Início a 01/03/2022 e término a 30/06/2023;
              </span>
            </li>
          </ul>
          <div className="flex flex-col md:flex-row gap-2 justify-between items-end">
            <div className="pl-3 mt-8">
              <p className="text-[#3A3A3A] font-bold">Cofinanciado por:</p>
              <img src={FileFooter} alt="" />
            </div>
            <a href="/Ficha_Projeto_SAFE.pdf" target="_blank" rel="noreferrer">
              <span>Consulte a Ficha Técnica em: <PictureAsPdfOutlinedIcon /></span>
            </a>
          </div>
        </div>
      </FileWrap>
    </>
  );
};
