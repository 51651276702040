import { Wrapper } from "./wrapper";
import { ReactComponent as Logo } from "../../logos/safe_logo_h.svg";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
import moment from "moment/moment";
function Footer() {
  return (
    <div id="footer" className="bg-[#1b1b1b] w-full pb-4">
      <Wrapper>
        <div className="flex flex-col py-10 md:flex-row sm:gap-10 md:justify-between">
          <div className=" flex justify-center mb-5 md:justify-start">
            <Logo />
          </div>
          <div className="flex-grow flex flex-col items-center md:items-start">
            <h5 className="text-[#525252] uppercase font-bold">Contactos</h5>
            <div className="grid grid-cols-1 grid-rows-4 md:grid-cols-2 md:grid-rows-2 gap-2 w-full mt-3">
              <div className="flex flex-col gap-2 items-center md:items-start">
                <p className="text-[#FFFFFF]">Telefone</p>
                <a
                  href={"tel:+351 291 103 690"}
                  className="text-[#F9F9F9] font-bold"
                >
                  (+351) 291 103 690
                </a>
              </div>
              <div className="flex flex-col gap-2 items-center md:items-start">
                <p className="text-[#FFFFFF]">Email</p>
                <a
                  href={"mailto:geral@futsafe.pt"}
                  className="text-[#F9F9F9] font-bold uppercase"
                >
                  geral@futsafe.pt
                </a>
              </div>
              <div
                className="flex flex-col cursor-pointer items-center md:items-start"
                onClick={() =>
                  window.open("https://goo.gl/maps/G4TibJf1UkRSwbYFA", "_blank")
                }
              >
                <p className="text-[#FFFFFF] mb-1">Morada</p>
                <p className="text-[#F9F9F9] font-bold uppercase">
                  Rua Dr. Fernão de Ornelas
                </p>
                <p className="text-[#F9F9F9] font-bold uppercase">
                  nº56, 4º AC
                </p>
                <p className="text-[#F9F9F9] font-bold uppercase">
                  9050-021 Funchal
                </p>
              </div>
              <div className="flex flex-col gap-2 justify-between h-full items-center md:items-start">
                <p className="text-[#ffffff]">Siga-nos em</p>
                <div className="flex gap-3 ">
                  <a
                    href="https://www.instagram.com/safefootballentrance/"
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                    className="text-white font-bold  border border-[#7F869566] rounded p-2"
                  >
                    <InstagramIcon />
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=100088082079907"
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                    className="text-white font-bold  border border-[#7F869566] rounded p-2"
                  >
                    <FacebookIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="basis-1/2 flex flex-col items-center md:items-start">
            <div className="flex flex-row gap-5">
              <div className="flex flex-col items-center gap-5 md:items-start "></div>
              <div className="flex flex-col items-center gap-10 md:flex-row "></div>
            </div>
          </div> */}
          <div className="mt-7 md:mt-0">
            <div className="flex flex-col gap-2 items-center md:items-start">
              <p className="text-[#525252] font-bold uppercase">Políticas</p>
              <Link to="/terms" className="text-white font-normal">
                Termos de Serviço
              </Link>
              <Link to="/privacy-policy" className="text-white font-normal">
                Política de privacidade
              </Link>
              <a
                href="https://www.livroreclamacoes.pt/"
                target={"_blank"}
                rel={"noopener noreferrer"}
                className="text-white font-normal"
              >
                Elogios, Sugestões e Reclamações
              </a>
            </div>
          </div>
        </div>
        <p className="text-[#F9F9F9] text-center">
          © {moment().format("YYYY")} por SAFE - Football Entrance. Todos os Direitos Reservados.
        </p>
      </Wrapper>
    </div>
  );
}

export default Footer;
