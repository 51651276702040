import styled from "styled-components";

export const NavigationBar = styled("header")`
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  background-color: ${(props) => (props.black ? "#1b1b1b" : "transparent")};
  display: flex;
  align-items: center;
  z-index: 1000;
  transition: 0.3s ease-in-out;

  &.scrolled {
    background-color: #fff;
    box-shadow: 0px 3.1px 7.8px rgba(0, 0, 0, 0.008),
      0px 4.8px 14.2px rgba(0, 0, 0, 0.004), 0px 6.4px 20.2px rgba(0, 0, 0, 0),
      0px 8px 26.7px rgba(0, 0, 0, 0), 0px 10.2px 35px rgba(0, 0, 0, 0.006),
      0px 13.3px 48.2px rgba(0, 0, 0, 0.024), 0px 21px 82px rgba(0, 0, 0, 0.07);
  }

  @media screen and (min-width: 768px) {
    padding: 0 16px;
  }
`;
export const NavigationLinks = styled("div")`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 30px;
  position: absolute;
  background-color: #000000cc;
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  color: #fff;
  padding-left: 20px;
  padding-top: 20px;
  top: 0;
  left: 0;
  margin-top: 52px;
  font-size: 16px;
  visibility: hidden;
  &.scrolled {
    color: #fff;
  }
  &.active {
    visibility: visible;
  }
  & a {
    text-transform: uppercase;
    font-weight: bold;
    transition: color 0.3s ease-in-out;
    &:hover {
      color: #f27033;
    }
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    position: relative;
    top: unset;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    background-color: unset;
    height: unset;
    width: unset;
    align-items: center;
    justify-content: unset;
    padding-top: unset;
    margin-top: unset;
    visibility: visible;

    &.scrolled {
      color: #000;
    }
  }
`;
